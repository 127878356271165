import { useRef, useState } from "react";
import { Carousel, Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import ModalBox from "../../Components/ModalBox/ModalBox";

export default function Speakers() {
  const carouselRef = useRef();
  const [open, setOpen] = useState(false);
  const [showData, setShowData] = useState("");

  const handleShowData = (items) => {
    setShowData(items);
    setOpen(true);
  };

  const SpeakersData = [
    {
      name: "Bala Arunachalam",
      position: "Novo Nordisk US",
      profileImage: AssetPath.speakers.balaArunachalam,
    },
    {
      name: "Balasubramanian Sankaranarayanan",
      position: "Thryve Digital",
      profileImage: AssetPath.speakers.balasubramanianSankaranarayanan,
    },
    {
      name: "Daniel Rose",
      position: "Global Strategy & Operations Leader",
      profileImage: AssetPath.speakers.danielRose,
    },

    {
      name: "Chetan Kulkarni",
      position: "Chryselys",
      profileImage: AssetPath.speakers.chetanKulkarni,
      desc: (
        <>
          <p>
            Chetan is a seasoned pharmaceutical professional with over a decade
            of experience in business insights, analytics, data science, and
            early commercial planning. He has a strong track record of
            delivering impactful insights, particularly in oncology, rare
            diseases and cell and gene therapies.{" "}
          </p>

          <p>
            As Executive Director at Chryselys, Chetan specializes in leveraging
            generative AI and advanced analytics to address client challenges.{" "}
          </p>

          <p>
            Prior to Chryselys, Chetan spent majority of his career in Novartis
            and Kite Pharma, A Gilead company, leading integrated insights and
            analytics teams. He built and led several teams in areas of brand
            analytics, forecasting and market research.
          </p>
        </>
      ),
    },

    {
      name: "Latha Srinivasan",
      position: "Senior Journalist",
      profileImage: AssetPath.speakers.lathaSrinivasan,
    },

    {
      name: "Mohit Sood",
      position: "ZS",
      profileImage: AssetPath.speakers.mohitSood,
      desc: (
        <>
          <p>
            Prakash earned a Bachelor of Engineering in Electronics and
            Communications from Andhra University, India.
          </p>
          <p>
            He has more than 15 years of life sciences consulting experience
            across commercial and clinical development. He also has an extensive
            experience in digital marketing, with a focus on multichannel
            resource optimization and promotion channel integration and
            orchestration. Mohit joined ZS in 2007 and has had five different
            roles within the firm in these 14+ years. Mohit was among the first
            fifty people to join ZS in India and has been an important part of
            the organization ever since.
          </p>

          <p>
            He is also fascinated about the integration of analytics and
            technology, particularly how it affects the delivery of large-scale
            data-driven insights. As an analytics leader, he led an initiative
            called "AnalyTechs" that aimed to change the way businesses perform
            and consume data analytics. Before joining ZS in 2007, Mohit worked
            in the IT industry, assisting global high-tech manufacturing clients
            in implementing Customer Relationship Management (CRM) solutions and
            using data driven customer engagement models.
          </p>
        </>
      ),
    },

    {
      name: "Vignesh Krishnakumar",
      position: "",
      profileImage: AssetPath.speakers.vigneshKrishnakumar,
    },
    {
      name: "Vikas Gade",
      position: "Chryselys",
      profileImage: AssetPath.speakers.vikasGade,
      desc: (
        <>
          <p>
            Vikas is a strategic business consultant with over 8 years of
            expertise in Oncology, specializing in business insights, analytics,
            and advanced decision-making frameworks.
          </p>
          <p>
            As an Associate Director at Chryselys, he leads key initiatives
            across HCP analytics, patient analytics, and field force operations,
            driving impactful solutions that enhance both clinical and
            commercial outcomes.
          </p>
          <p>
            Prior to Chryselys, Vikas built a strong foundation at Novartis and
            ZS, where he focused on patient analytics, segmentation & targeting,
            and advanced analytics. His deep experience in these areas enables
            him to deliver data-driven strategies that consistently maximize
            business potential in the competitive pharmaceutical landscape.
          </p>
        </>
      ),
    },
    {
      name: "Prakash Karaka",
      position: "Chief AI Officer",
      profileImage: AssetPath.speakers.prakashKaraka,
      desc: (
        <>
          <p>
            As Chief AI Officer, Prakash is a guide and a collaborator with
            ethics and governance in mind. His focus is on integrating AI and
            Generative AI technologies, steering Chryselys’ vision towards
            harnessing vast datasets and yielding novel insights & solutions.
            This involves leveraging AI’s predictive capabilities and GenAI’s
            creative potential to revolutionize solutions to address client
            needs.
          </p>
          <p>
            With a deep commitment to leveraging technology for healthcare
            advancement, Prakash brings over 17 years of experience in
            engineering, AI, data analytics, and machine learning to the
            pharmaceutical management science field.
          </p>
          <p>
            Prakash is at the forefront of developing AI solutions that are
            secure, personalized, and transformative for the Pharma industry.
            His roles at Amazon, Microsoft, and Intel have been marked by
            significant contributions in areas ranging from cloud-based big data
            analytics to AI-driven retail and developer services.
          </p>
          <p>
            Prakash earned a Bachelor of Engineering in Electronics and
            Communications from Andhra University, India.
          </p>
        </>
      ),
    },

    {
      name: "Siddharth Mandal",
      position: "Senior Manager Data Science",
      profileImage: AssetPath.speakers.siddharthMandal,
    },
    {
      name: "Suzanne Marzziotti",
      position: "Head, Business Solutions",
      profileImage: AssetPath.speakers.suzanneMarzziotti,
      desc: (
        <>
          <p>
            With over 30 years of enriching the pharmaceutical and CPG
            industries with actionable insights, Suzanne Marziotti spearheads
            the business solutions vertical at Chryselys. In her role as Head of
            Business Solutions, she strategically applies data to catalyze
            growth and customize solutions that align with the complex needs of
            the pharma industry.
          </p>

          <p>
            Suzanne’s professional journey is marked by a strong emphasis on
            precision medicine reporting and commercial analytics, fostering the
            use of generative AI to enhance predictive modelling across
            therapeutic domains.
          </p>
          <p>
            Her approach weaves together cutting-edge technology and in-depth
            industry knowledge, resulting in robust strategies that elevate both
            client experience and patient care.
          </p>
          <p>
            Suzanne holds a BS in Microbial Genetics from Cornell University,
            complemented by an MBA from NYU’s Stern School of Business, which
            has empowered her to merge scientific inquiry with strategic
            implementation.
          </p>
        </>
      ),
    },
  ];

  const carouselConfig = {
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section
        className="speakers our-team spacing position-relative bg-light-green"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
            <div className="text-center w-100">
              <h4 className="title mb-3 text-primary text-center">
                Meet the Speakers
              </h4>
            </div>
          </div>
          <div className="team-list">
            <Carousel {...carouselConfig} ref={carouselRef}>
              {SpeakersData.map((members, i) => (
                <div
                  key={i}
                  className="team-details position-relative"
                  onClick={() => handleShowData(members)}
                >
                  <div className="profile-image rounded overflow-hidden">
                    <img
                      src={members.profileImage}
                      alt={members.name}
                      className="w-100 rounded"
                    />
                  </div>
                  <div className="details text-center card">
                    <h4 className="font-4 font-weight-bold mb-1">
                      {members.name}
                    </h4>
                    <p className="text-ellipsis2 mb-0">{members.position}</p>

                    <p className="text-underline mt-2 text-primary">
                      Know More
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <div className="speaker-profile-informations">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <div className="profile-image rounded overflow-hidden">
                <img
                  src={showData.profileImage}
                  alt={showData.name}
                  className="w-100 rounded"
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}
            >
              <div className="details text-left card">
                <h4 className="text-primary mb-1 border-bottom pb-2">
                  <span className=" font-weight-bold">{showData.name}</span> -{" "}
                  <span className="font-2 fon-weight-normal text-gray">
                    {showData.position}
                  </span>
                </h4>

                <h5 className="text-primary">Know More</h5>
                <div className="profile-desc">{showData.desc}</div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBox>
    </>
  );
}
