// images
import contactImage from "../assets/images/background/contact-us.jpg";
import ItcChennaiIndia from "../assets/images/itc-chennai-india.png";
import contact from "../assets/images/contact.png";

// brand
import pharmaSos from "../assets/images/brand/pharma-sos-logo.png";
import pharmaSosWhite from "../assets/images/brand/pharma-sos-logo-white.png";

// sliders
import indiaConference from "../assets/images/slides/pharma-sos-india-conference.jpg";

// about us images
import aboutUs from "../assets/images/about-us/about.jpg";

// shape
import brushStroke from "../assets/images/shape/brush-stroke.svg";
import brushStrokeGreen from "../assets/images/shape/brush-stroke-green.svg";
import brushStrokeWhite from "../assets/images/shape/brush-stroke-white.svg";
import brushStrokeGreenFlip from "../assets/images/shape/brush-stroke-green-flip.svg";
// events details
// import chennaiEvents from "../assets/images/events/pharma-sos-indian-conference.jpg";
import chennaiEvents from "../assets/images/events/innovate-pharma.jpg";
import summerEventMorristown from "../assets/images/events/summer-camp-morritown-nj.jpg";
import eventVenetianCasinoLasvegas from "../assets/images/events/event-venetian-casino-las-vegas.jpg";

// team details
import KarthikChidambaram from "../assets/images/team/board-of-directors/karthik-chidambaram.jpg";
import ashishPatel from "../assets/images/team/board-of-directors/ashish-patel.jpg";
import daveBitner from "../assets/images/team/board-of-directors/dave-bitner.jpg";
import davidPurdie from "../assets/images/team/board-of-directors/david-purdie.jpg";
import janeChen from "../assets/images/team/board-of-directors/jane-chen.jpg";
import jasonCarlin from "../assets/images/team/board-of-directors/jason-carlin.jpg";
import jinTong from "../assets/images/team/board-of-directors/jin-tong.jpg";
import paulomiPatel from "../assets/images/team/board-of-directors/paulomi-patel.jpg";
import sakthiAnnamalai from "../assets/images/team/board-of-directors/sakthi-annamalai.jpg";
import shanYeh from "../assets/images/team/board-of-directors/shan-yeh.jpg";
import somySilvy from "../assets/images/team/board-of-directors/somy-silvy.jpg";

// breadcrumb
import aboutUsImage from "../assets/images/background/about-us.jpg";
import contactUsImage from "../assets/images/background/contact-us.jpg";
import agendaImage from "../assets/images/background/agenda.jpg";
import sponsorsImage from "../assets/images/background/sponsors.jpg";
import eventandRegistration from "../assets/images/background/registration.jpg";
import joinWithUsImage from "../assets/images/background/join-with-us.jpg";
import subscribeNewsletter from "../assets/images/background/subscribe-newsletter-team.jpg";
import becomeVolunteers from "../assets/images/background/volunteers.jpg";
import contactBg from "../assets/images/background/contact.jpg";
import abstractSubmission from "../assets/images/background/abstract-submission.jpg";
// volunteers
import jessYoung from "../assets/images/team/volunteers/jess-young.jpg";
import amyBenford from "../assets/images/team/volunteers/amy-benford.jpg";
import ashleyPeterson from "../assets/images/team/volunteers/ashley-peterson.jpg";
import deviPrasadSingh from "../assets/images/team/volunteers/devi-prasad-singh.jpg";

// speakers
import balaArunachalam from "../assets/images/team/speakers/bala-arunachalam.jpg";
import balasubramanianSankaranarayanan from "../assets/images/team/speakers/balasubramanian-sankaranarayanan.jpg";
import danielRose from "../assets/images/team/speakers/daniel-rose.jpg";
import lathaSrinivasan from "../assets/images/team/speakers/latha-srinivasan.jpg";
import mohitSood from "../assets/images/team/speakers/mohit-sood.jpg";
import chetanKulkarni from "../assets/images/team/speakers/chetan-kulkarni.jpg";
import siddharthMandal from "../assets/images/team/speakers/siddharth-mandal.jpg";
import vigneshKrishnakumar from "../assets/images/team/speakers/vignesh-krishnakumar.jpg";
import vikasGade from "../assets/images/team/speakers/vikas-gade.jpg";
import suzanneMarzziotti from "../assets/images/team/speakers/suzanne-marzziotti.jpg";
import prakashKaraka from "../assets/images/team/speakers/prakash-karaka.jpg";

const AssetPath = {
  brand: {
    pharmaSos,
    pharmaSosWhite,
  },
  cta: {},

  breadcrumb: {
    aboutUsImage,
    contactUsImage,
    agendaImage,
    sponsorsImage,
    eventandRegistration,
    joinWithUsImage,
    subscribeNewsletter,
    becomeVolunteers,
    abstractSubmission,
  },
  sliders: {
    indiaConference,
  },
  images: {
    aboutUs,
    contactImage,
    ItcChennaiIndia,
    contact,
    contactBg,
  },
  shape: {
    brushStroke,
    brushStrokeGreen,
    brushStrokeWhite,
    brushStrokeGreenFlip,
  },
  events: {
    chennaiEvents,
    summerEventMorristown,
    eventVenetianCasinoLasvegas,
  },
  team: {
    // boardof directors
    KarthikChidambaram,
    ashishPatel,
    somySilvy,
    davidPurdie,
    daveBitner,
    janeChen,
    jasonCarlin,
    jinTong,
    paulomiPatel,
    sakthiAnnamalai,
    shanYeh,

    // volunteers
    jessYoung,
    amyBenford,
    ashleyPeterson,
    deviPrasadSingh,
  },
  speakers: {
    balaArunachalam,
    balasubramanianSankaranarayanan,
    danielRose,
    lathaSrinivasan,
    mohitSood,
    chetanKulkarni,
    siddharthMandal,
    vigneshKrishnakumar,
    vikasGade,
    suzanneMarzziotti,
    prakashKaraka,
  },
};

export default AssetPath;
