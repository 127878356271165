import React, { useState } from "react";
import CTA from "../LandingPage/CTA";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import AgendaList from "./AgendaList";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SubmitAbstractForm from "../LandingPage/SubmitAbstractForm";
import Speakers from "./Speakers";
import { useHistory } from "react-router-dom";
import SponsorPackages from "../Sponsors/SponsorPackages";

export default function Agenda() {
  const [open, setOpen] = useState();
  const history = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Agenda"
        breadcrumbItems={[{ name: "Agenda", path: "/agenda" }]}
        image={AssetPath.breadcrumb.agendaImage}
        strokeType="green"
      />
      <AgendaList />

      <Speakers />

      <SponsorPackages className="spacing-bottom" />

      <CTA
        subTitle="Submit Your Abstract"
        desc="Present your cutting-edge insights at Innovate Pharma 2024. Engage with industry leaders, contribute to pivotal discussions, and collaborate with global experts!"
        label="Submit"
        noImage
        onClick={() => history.push("/abstract-submission")}
      />

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <SubmitAbstractForm />
      </ModalBox>
    </>
  );
}
