import { Col, Row } from "antd";
import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";

export default function Privacy() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Privacy Policy"
        breadcrumbItems={[{ name: "Privacy Policy", path: "/privacy-policy" }]}
        image={AssetPath.breadcrumb.agendaImage}
        strokeType="green"
      />
      <section className="faq spacing bg-light-green">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div class="highlight-title" data-aos="fade-up">
                <h3>Privacy Policy - Pharma-SOS</h3>
                <p>Last Updated: July 22, 2024</p>

                <h4>Introduction:</h4>
                <p>
                  Welcome to Pharma-SOS | Commercial Operations Community ("we,"
                  "our," or "us"). We are committed to protecting your privacy
                  and safeguarding your personal information. This Privacy
                  Policy explains how we collect, use, disclose, and protect
                  your information when you visit our website{" "}
                  <a
                    href="https://www.pharma-sos.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.pharma-sos.org
                  </a>{" "}
                  ("Website") and interact with our services. By using our
                  Website, you consent to the practices described in this
                  Privacy Policy.
                </p>

                {/* ... Other sections ... */}

                <h4>Contact Us:</h4>
                <p>
                  If you have any questions, concerns, or requests related to
                  your privacy or this Privacy Policy, please contact us at{" "}
                  <a href="mailto:info@pharma-sos.com">info@pharma-sos.com</a>.
                </p>

                <h4>Changes to this Privacy Policy:</h4>
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with a revised "Last
                  Updated" date.
                </p>

                <p>
                  Thank you for trusting Pharma-SOS | Commercial Operations
                  Community with your information.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
