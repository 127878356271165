import AssetPath from "../AssetPath/AssetPath";

const TeamData = {
  boardMembers: [
    {
      name: "Karthik Chidambaram",
      position: "President",
      linkedinUrl: "",
      profileImage: AssetPath.team.KarthikChidambaram,
    },
    {
      name: "Jin Tong",
      position: "Vice President & Treasurer",
      linkedinUrl: "",
      profileImage: AssetPath.team.jinTong,
    },
    {
      name: "Jason Carlin",
      position: "Vice President & Membership",
      linkedinUrl: "",
      profileImage: AssetPath.team.jasonCarlin,
    },
    {
      name: "Shan Yeh",
      position: "Board Director, 2025 Conference Chair",
      linkedinUrl: "",
      profileImage: AssetPath.team.shanYeh,
    },
    {
      name: "Jane Chen",
      position: "Board Director, Program Committee",
      linkedinUrl: "",
      profileImage: AssetPath.team.janeChen,
    },
    {
      name: "Sakthi Annamalai",
      position: "Board Director, India Conference Chair",
      linkedinUrl: "",
      profileImage: AssetPath.team.sakthiAnnamalai,
    },
    {
      name: "Somy Silvy",
      position: "Board Director, Member Outreach - East",
      linkedinUrl: "",
      profileImage: AssetPath.team.somySilvy,
    },
    {
      name: "Ashish Patel",
      position: "Board Director, Podcasts",
      linkedinUrl: "",
      profileImage: AssetPath.team.ashishPatel,
    },
    {
      name: "Dave Bitner",
      position: "Board Director, Member Outreach - West",
      linkedinUrl: "",
      profileImage: AssetPath.team.daveBitner,
    },
    {
      name: "David Purdie",
      position: "Board Director, Journal & Publications",
      linkedinUrl: "",
      profileImage: AssetPath.team.davidPurdie,
    },
    {
      name: "Paulomi Patel",
      position: "Board Director, Marketing",
      linkedinUrl: "",
      profileImage: AssetPath.team.paulomiPatel,
    },
  ],

  volunteers: [
    {
      name: "Jess Young",
      position: "2025 Conference",
      linkedinUrl: "",
      profileImage: AssetPath.team.jessYoung,
    },
    {
      name: "Amy Benford",
      position: "2025 Conference",
      linkedinUrl: "",
      profileImage: AssetPath.team.amyBenford,
    },
    {
      name: "Ashley Peterson",
      position: "2025 Conference",
      linkedinUrl: "",
      profileImage: AssetPath.team.ashleyPeterson,
    },
    {
      name: "Devi Prasad Singh",
      position: "2024 India Conference",
      linkedinUrl: "",
      profileImage: AssetPath.team.deviPrasadSingh,
    },
  ],
};

export default TeamData;
